import PropTypes from "prop-types"
import React from "react"
import Image from './image'
import Link from './Link'
import DesktopHeader from './desktopHeader'
import MobileHeader from './mobileHeader'
import {AppBar, Button, IconButton, Toolbar, Grid, Hidden, makeStyles} from '@material-ui/core'
import { graphql, useStaticQuery } from "gatsby"




const Header = ({siteTitle}) => {

  const data = useStaticQuery(graphql`
  query SiteQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
  }
  `)

  return (
    <div>
    <Hidden mdDown>
      <DesktopHeader menuLinks = {data.site.siteMetadata.menuLinks}/>
    </Hidden>
    <Hidden lgUp>
      <MobileHeader menuLinks = {data.site.siteMetadata.menuLinks}/>
    </Hidden>
    </div>
  )
}

export default Header
