import PropTypes from "prop-types"
import React from "react"
import Image from './image'
import Link from './Link'
import {AppBar, Button, IconButton, Toolbar, Grid, Hidden, makeStyles} from '@material-ui/core'
import {navigate} from 'gatsby'

interface MenuLink{
  name: string,
  link: string
}

interface HeaderProps {
  menuLinks: MenuLink[]
}
const useStyles = makeStyles({
  root: {
    alignItems: 'center'
  },
  gridItem: {
    position: 'relative',
    height: '100%'

  },
})

const DesktopHeader = (props: HeaderProps) => {
  const classes = useStyles()

  const renderMenuLinks = (menuLinks: MenuLink[]) => {
    return menuLinks.map((menuLink) =>(
      <Grid container item xs={1} justify='center' key={menuLink.link}>
        <Button onClick={() => {navigate(menuLink.link)}}>
        <Link to={menuLink.link}>
          {menuLink.name}
        </Link>
        </Button>
      </Grid>
    ))
  }
  
  return (
  <AppBar color='default'>
            <Toolbar>
          <Grid justify={"space-around"} container>
            <Grid xs={6} item>
              <Link to="/">
                <Image/>
              </Link>
            </Grid>
            {renderMenuLinks(props.menuLinks)}
          </Grid>
        </Toolbar>
    
  </AppBar>
  )
}

export default DesktopHeader
