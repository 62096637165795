import PropTypes from "prop-types"
import React from "react"
import Image from './image'
import Link from './Link'
import {AppBar, IconButton, Toolbar, Grid, Drawer, MenuItem} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import {navigate} from 'gatsby'

interface MenuLink{
    name: string,
    link: string
  }
  
  interface HeaderProps {
    menuLinks: MenuLink[]
  }

const MobileHeader = (props:HeaderProps) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handleDrawerOpen =  () => {
        setDrawerOpen(true)
    }
    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }
    const getDrawerChoices = (menuLinks:MenuLink[]) => {
        return menuLinks.map((menuLink) => {
            return (
                <MenuItem key={menuLink.name} onClick={() => navigate(menuLink.link)}>
                <Link to={menuLink.link}>
                    {menuLink.name}
                </Link>
                </MenuItem>
            )
        })
    }

  return (
  <AppBar color='default'>
            <Toolbar>
          <Grid justify={"space-between"} container>
            <Grid xs={1} item>
              <Link to="/">
                <Image/>
              </Link>
            </Grid>
            <Grid xs={1} item>
                <IconButton {
                    ...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup":"true",
                        onClick: handleDrawerOpen,
                    }
                }>
                    <MenuIcon/>
                </IconButton>
            </Grid>
          </Grid>
          <Drawer
              {...{
                  anchor: 'left',
                  open: drawerOpen,
                  onClose: handleDrawerClose,
              }}>
                  <div>{getDrawerChoices(props.menuLinks)}</div>
          </Drawer>
        </Toolbar>
  </AppBar>
  )
}

export default MobileHeader
