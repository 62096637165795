/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {Typography, Grid, makeStyles, useTheme} from "@material-ui/core"
import Header from "./header"
import Map from './Map'
import "./layout.css"
import { BorderColor } from "@material-ui/icons"

const useStyles = makeStyles({
  minititle: {
    marginTop: '1rem',
    fontWeight: 500
  },
  title: {
    fontWeight:500,
    fontSize: 40,
    marginBottom: '2rem'
  },
  bold: {
    fontWeight:500
  },
  footer: {
    textAlign:'center', 
    padding: '3rem',
    backgroundColor: '#BBBCDD'
  }
})

const Layout = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <>
    <Header/>
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main style={{
          minHeight: `40vh`
        }}>{children}</main>
        <footer className={classes.footer}>
          <Grid container justify="space-evenly">
          
          <Grid item>
          <Typography className={classes.title}>Om oss</Typography>
          <Typography>post@melhus-minilager.no</Typography>
          <Typography>Tlf 483 00 609</Typography>
          <Typography className={classes.minititle}>Melhus Minilager AS </Typography>
          <Typography>Org nr: 925 540 250</Typography>
          <Typography>Øysandvegen 205</Typography>
          <Typography>7224 Melhus</Typography>
          </Grid>
          <Grid item>
            <Map/>
          </Grid>
          </Grid>

          © {new Date().getFullYear()}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
