import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '280px',
  height: '280px'
};

const pos = {
  lat: 63.32531,
  lng: 10.22699
};

function MyComponent() {
  const [map, setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <LoadScript
      googleMapsApiKey={process.env.GATSBY_MAPS_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={pos}
        zoom={10}
        onUnmount={onUnmount}
      >
    <Marker
      position={pos}
      icon={{
        url:require('../images/mapmarker.png')
      }}
    />
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyComponent)